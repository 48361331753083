.galleryItem {
  :global {
    .image-gallery-image {
      object-fit: contain;
      height: 500px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .galleryItem {
    :global {
      .image-gallery-image {
        height: 315px;
      }
    }
  }
}
