.gd-carousel-wrapper {
  position:relative;

}

.gd-carousel {
  position:unset;

  .react-multiple-carousel__arrow {
      position:absolute;
  }

  .react-multiple-carousel__arrow--left {
      left: calc(-3% + 1px)!important
  }

  .react-multiple-carousel__arrow--right {
      right: calc(-3% + 1px)
  }
}