@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700;800&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .placeholder-200::placeholder{
    font-weight: 100;
    color: rgba(0, 0, 0, 0.5)
  }
  .animation-delay-300 {
    animation-delay: 300ms;
  }
  .animation-delay-400 {
    animation-delay: 500ms;
  }
  .animation-delay-500 {
    animation-delay: 500ms;
  }
  .animation-delay-600 {
    animation-delay: 500ms;
  }
  .animation-delay-75 {
    animation-delay: 75ms;
  }
  .animation-delay-1000 {
    animation-delay: 1s;
  }
  .animation-fill {
    animation-fill-mode: forwards;
  }
  .animation-fadeIn {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-name: fadeIn;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }
  .all-unset {
    all: unset;
  }
  .h-unset {
    height: unset;
  }
  .w-unset {
    width: unset;
  }
  .left-unset {
    left: unset;
  }
  .right-unset {
    right: unset;
  }
  .title-1 {
    @apply text-[3rem] md:text-[6.75rem] leading-[3rem] md:leading-[5rem];
  }
  .title-2 {
    @apply text-[2.2rem] md:text-[6.5625rem] md:leading-[20px] uppercase;
  }
  .title-3 {
    @apply text-[1.375rem] md:text-[2.375rem];
  }
  .text-size {
    @apply text-[0.8125rem] md:text-[1.125rem]
  }
  .sub-text-size {
    @apply text-[0.8125rem] leading-5
  }
  .padding-x-box {
    @apply px-[4%];
  }
  .gap-box {
    
  }
  .margin-box {
    @apply mb-[35%] md:mb-[25%];
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.boxHover {
  transition: all 0.6s ease-in-out;
}
/* .font-poppins {
  div,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif;
  }
} */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
  font-family: "Nunito";
}

div,
input,
textarea {
  color: #000;
  font-family: "Nunito";
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

svg {
  user-select: none;
}

.image-gallery-right-nav, .image-gallery-left-nav  {
  .image-gallery-svg {
    height: 90px;
    width: 45px;
  }
}

.image-gallery-thumbnail-image {
  height: 80px;
  object-fit: cover;
}

.image-gallery-bullet {
  padding: 3px!important;
}

.image-gallery-thumbnail-inner {
  width: 100%;
  height: 100%;
}

.image-gallery-thumbnail-image {
  height: 100%;
  object-fit: cover;
}

.image-gallery-left-nav {
  top: unset;
  bottom: 0;
  transform: translateY(175px);
  left: -60px;
}

.image-gallery-right-nav {
  top: unset;
  bottom: 0;
  transform: translateY(175px);
  right: -60px;
}


@media only screen and (max-width: 1024px) {
  .image-gallery-left-nav {
    display: none;
  }
  .image-gallery-right-nav {
    display: none;
  }
}
